import { Button, Grid } from '@material-ui/core';
import React, { useState } from 'react';

import Router from 'next/router';
import CartItem from 'components/Cart/CartItem';
import { useCartGetter, useCartDispatcher } from 'contexts/CartContext';

/**
 * Cart Container
 *
 * @param props - passes in cart from redux and dispatch actions for cart items
 *
 * Container for cart items that passes in cart array to create cart items
 * Contains logic for submitting an order to be created
 */
const CartContainer = (props) => {
  // gets cart from redux store
  const {
    resetCheckout, mini,
  } = props;
  const cart = useCartGetter();
  const dispatchCart = useCartDispatcher();

  // state for error message
  const [state] = useState({
    hasMessage: false,
    message: '',
    submit: false,
  });

  // button handler for removing an item
  const handleRemoveItem = (index) => {
    dispatchCart({ type: 'removeItem', payload: index });
  };

  // button handler for editing an item
  const handleEditItem = (index) => {
    // get item from store
    Router.push({
      pathname: '/create-order',
      query: { id: index },
    });
  };

  // on add another item reset store and route back
  const handleAddAnotherItem = () => {
    Router.push('/create-order');
  };

  // create orders map
  let orders = null;
  if (cart?.items?.length > 0) {
    // map orders
    orders = cart?.items.map((item, index) => (
      <CartItem
        key={index}
        mini={mini}
        index={index}
        remove={() => {
          handleRemoveItem(index);
          if (!mini) {
            resetCheckout();
          }
        }}
        edit={() => handleEditItem(index)}
        item={item}
      />
    ));
  } else {
    // return no orders
    orders = <p>{'No items currently in cart'}</p>;
  }

  return (
    <>
      <div className='cart-wrapper'>
        <Grid container className='cart-items-wrapper'>
          {orders}
        </Grid>
      </div>
      {state.hasMessage && <p className='error'>{state.message}</p>}
      <div className='button-wrapper'>
        <Button color='primary' variant='contained' onClick={handleAddAnotherItem} id='add-another-item-button'>
          {'Add Another Item'}
        </Button>
      </div>
    </>
  );
};

export default CartContainer;
