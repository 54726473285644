import { List } from '@material-ui/core';
import React from 'react';
import { format } from 'date-fns';
import { useUser } from '@auth0/nextjs-auth0';
import NavLinks from 'components/Global/NavLinks';
import { formatImage } from 'utils/imageService';
import { useContent } from 'utils/swrCalls';
import * as site from 'utils/getSite';

const isMacys = site.getSite.includes('macys');

const Footer = () => {
  const { user: session } = useUser();
  const { data: content } = useContent();

  const LoadFooter = () => {
    if (isMacys) {
      return (
        <>
          <img className='wnm-footer-logo' src={formatImage(content?.content?.globalFooter?.logo?.path)} title={content?.content?.globalHeader?.logo?.title} alt='footer-logo' />
        </>
      );
    }
    return (
      <>
        <p>
          &copy;
          {' '}
          {format(new Date(), 'Y')}
          {' '}
          <a href='https://myjewelryrepair.com/'>{'MyJewelryRepair.com'}</a>
        </p>
      </>
    );
  };

  return (
    <footer className='g-footer'>
      {session && (
        <div className='footer-nav-links'>
          <List>
            <NavLinks />
          </List>
        </div>
      )}
      <LoadFooter />
    </footer>
  );
};

export default Footer;
