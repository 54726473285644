import React from 'react';

// id
export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID;

// categories
export const ADD_TO_CART = 'add_to_cart';
export const CHECKOUT = 'checkout';
export const ORDER_SUMMARY = 'order_summary';
export const ORDER_HISTORY = 'order_history';

// handles event for pageview
export const GTMPageView = (url) => {
  const pageEvent = {
    event: 'pageview',
    page: url,
  };
  // eslint-disable-next-line no-unused-expressions
  window && window.dataLayer && window.dataLayer.push(pageEvent);
  return pageEvent;
};

// Google Tag snippet
export const GATAG = () => (
  <>
    {/* Global Site Tag (gtag.js) - Google Analytics */}
    <script
      async
      src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_TRACKING_ID}`}
    />
    <script
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GA_TRACKING_ID}');`,
      }}
    />
  </>
);
