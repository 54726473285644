import { Button, Grid, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import React from 'react';
import { useRouter } from 'next/router';

/**
 * Cart Item Component
 *
 * @param props
 *
 * Individual Item inside the cart
 */
const CartItem = (props) => {
  const {
    index, item, remove, edit, mini,
  } = props;
  const routeParams = useRouter().query;
  const id = routeParams.id === undefined ? null : parseInt(routeParams.id);
  const handleRemoveItem = (index) => {
    remove(index);
  };
  const handleEditItem = (index) => {
    edit(index);
  };

  return (
    <Grid item container className='item-wrapper' key={index}>
      <Grid container spacing={3} className='item-details'>
        <Grid item xs={12} sm={!mini ? 4 : 12} className='detail-wrapper'>
          <Typography variant='body1'>{item.issue}</Typography>
          {id === index ? (
            <Typography variant='body1' color='secondary' className='edit-message'><strong>{'Currently editing...'}</strong></Typography>
          ) : (
            <div className='item-controls'>
              <Button className='edit-item-button' color='secondary' variant='contained' onClick={() => handleEditItem(index)}>
                {'Edit'}
              </Button>
              {id === null && (
                <Button className='remove-item-button' color='primary' variant='contained' onClick={() => handleRemoveItem(index)}>
                  {'Remove'}
                </Button>
              )}
            </div>
          )}
        </Grid>
        <Grid item xs={12} sm={!mini ? 8 : 12} className='description'>
          <Typography variant='h4'>{'Description: '}</Typography>
          <Typography variant='body1' className='itemDescription'>{item.customerNotes}</Typography>
          {item.companyNameEnum !== '4' && (
            <>
              <Typography variant='h4'>
                {'Declared Value: '}
              </Typography>
              <Typography variant='body1'>
                <NumberFormat
                  value={item.declaredValueUsd}
                  displayType='text'
                  thousandSeparator
                  fixedDecimalScale
                  decimalScale={2}
                  prefix='$'
                />
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CartItem;
