import React from 'react';
import IdleChecker from 'components/Global/IdleChecker';
import CartDrawer from 'components/Cart/CartDrawer';
import Header from 'components/Global/Header';
import Footer from 'components/Global/Footer';
import CookieBanner from 'components/Global/CookieBanner';
import { useUser } from '@auth0/nextjs-auth0';
import { useRouter } from 'next/router';
import { useContent, useMjrApiUser } from 'utils/swrCalls';

/**
 * GlobalLayout
 *
 * @param props
 *
 * Layout component that wraps all child components in app
 */
const GlobalLayout = (props) => {
  const { children } = props;

  const { user: session, isLoading } = useUser();
  const router = useRouter();
  const userNeedsToLogIn = !session && !isLoading && router.pathname !== '/review-estimate';
  if (userNeedsToLogIn) router.push('/api/auth/login');
  const { data: user, isValidating: userIsValidating } = useMjrApiUser();
  const { data: content } = useContent();

  const userNeedsToBeRegisteredInBackEnd = (session && !user && userIsValidating === false && router.pathname !== '/review-estimate');
  if (userNeedsToBeRegisteredInBackEnd && router.pathname !== '/create-account') router.push('/create-account');

  if (router.pathname === '/review-estimate') {
    return (
      <div>
        <Header />
        <div className='main container'>
          {children}
        </div>
        <Footer />
      </div>
    )
  }

  return (
    <>
      {session
      && (user?.customerId || router.pathname === '/create-account')
      && content
      && (
      <div>
        <IdleChecker />
        <Header />
        <div className='main container'>
          {children}
          <CartDrawer />
        </div>
        <Footer />
        <CookieBanner />
      </div>
      )}
    </>
  );
};

export default GlobalLayout;
