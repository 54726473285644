import {
  AppBar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  SwipeableDrawer,
  Toolbar,
  Typography,
} from '@material-ui/core';

import Link from 'next/link';
import { Menu } from '@material-ui/icons';
import React from 'react';
import { formatImage } from 'utils/imageService';
import { makeStyles } from '@material-ui/core/styles';
import NavLinks from 'components/Global/NavLinks';
import { useUser } from '@auth0/nextjs-auth0';
import { useCartDispatcher } from 'contexts/CartContext';
import { useContent, useMjrApiUser } from 'utils/swrCalls';
import * as site from 'utils/getSite';
import {useRouter} from "next/router";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 300,
  },
  fullList: {
    width: 'auto',
  },
}));

/**
 * Header Component
 */
const Header = () => {
  const dispatchCart = useCartDispatcher();
  const router = useRouter();
  const { data: content } = useContent();
  const { user: session } = useUser();
  const { data: user } = useMjrApiUser();
  const classes = useStyles();
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (side, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [side]: open });
  };

  // Setting main logo's CSS class based on current site we are on.
  const getLogoCSS = () => {
    if (router.pathname === '/review-estimate') return ' macys';
    return (site.getSite.includes('montblanc') ? ' montblanc' : site.getSite.includes('macys') ? ' macys' : '');
  }

  /**
   * logout
   *
   * handler for user logout button
   */
  const logout = () => {
    dispatchCart({ type: 'clear' });
    window.location = '/api/auth/logout';
  };

  /**
   * sideDrawer
   *
   * @param side - direction toggle
   *
   * container and logic for controlling slide toggle of mobile nav
   */
  const sideDrawer = (side) => (
    <div
      className={classes.list}
      role='presentation'
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <List className='drawer-nav-container'>
        {user && (
        <div className='name-header'>
          <Typography variant='h2'>
            {user.firstName}
            {' '}
            {user.lastName}
          </Typography>
        </div>
        )}
        <Button onClick={logout} className='sign-out-btn' variant='contained' color='secondary'>
          {'Sign Out'}
        </Button>
        <NavLinks />
      </List>
    </div>
  );

  return (
    <div className='g-header'>
      <AppBar position='static'>
        <Toolbar>
          <Link href='https://myjewelryrepair.com/'>
            <Button color='inherit' id='logo' className={`logo-wrapper${getLogoCSS()}`}>
              <img src={formatImage(content?.content?.globalHeader?.logo?.path)} title={content?.content?.globalHeader?.logo?.title} alt='logo' />
            </Button>
          </Link>
          {session && (
            <Box className='main-links hidden-md-up'>
              <IconButton
                className='menuButton'
                edge='start'
                color='inherit'
                aria-label='menu'
                id='mobile-menu-button'
                onClick={toggleDrawer('right', true)}
              >
                <Menu />
                {' '}
                <span>{'Menu'}</span>
              </IconButton>
            </Box>
          )}
        </Toolbar>
        <Toolbar className='hidden-sm'>
          {session && (
            <Box className='desktop-nav container'>
              <List>
                <NavLinks />
                <ListItem>
                  <Button onClick={logout} variant='text' id='sign-out-button'>
                    {'Sign Out'}
                  </Button>
                </ListItem>
              </List>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        anchor='right'
        open={state.right}
        onOpen={toggleDrawer('right', true)}
        onClose={toggleDrawer('right', false)}
      >
        {sideDrawer('right')}
      </SwipeableDrawer>
    </div>
  );
};

export default Header;
