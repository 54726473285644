import React from 'react';
import { Badge, IconButton } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { withStyles } from '@material-ui/core/styles';
import { useCartGetter } from 'contexts/CartContext';

const StyledBadge = withStyles(() => ({
  badge: {
    right: 5,
    top: 13,
    border: '1px solid #fff',
    padding: '0 4px',
  },
}))(Badge);

const CartButton = (props) => {
  const { clickHandler } = props;
  const cart = useCartGetter();

  return (
    <IconButton aria-label='cart' onClick={clickHandler}>
      <StyledBadge badgeContent={cart.items.length} color='primary'>
        <ShoppingCartIcon />
      </StyledBadge>
    </IconButton>
  );
};

export default CartButton;
