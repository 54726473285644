import 'assets/scss/main.scss';

import CssBaseline from '@material-ui/core/CssBaseline';
import ErrorMessage from 'components/Global/ErrorMessage';
import Head from 'next/head';
import Layout from 'components/Global/Layout';
import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { ThemeProvider } from '@material-ui/styles';
import theme from 'utils/theme';
import { UserProvider } from '@auth0/nextjs-auth0';
import * as gtag from 'utils/gtag';
import HeadConfig from 'components/Global/HeadConfig';
import TagManager from 'react-gtm-module';
import { CartProvider } from 'contexts/CartContext';
import { OrderProvider } from 'contexts/OrderContext';
import applicationInsights from "../utils/applicationInsights";

/**
 * App
 * Page/Component
 *
 * @param {*} props
 *
 * Contains application setup
 */
const MyApp = (props) => {
  const { Component, pageProps } = props;

  // google analytics setup
  const router = useRouter();
  useEffect(() => {
    TagManager.initialize({ gtmId: `${process.env.NEXT_PUBLIC_GA_TRACKING_ID}` });
    const handleRouteChange = (url) => {
      gtag.GTMPageView(url);
      applicationInsights.trackPageView({ name: url });
    };

    const handleError = (error) => {
      applicationInsights.trackException({ exception: error });
    };

    const handleRejection = (event) => {
      applicationInsights.trackException({ exception: event.reason });
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    window.addEventListener('error', handleError);
    window.addEventListener('unhandledrejection', handleRejection);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      window.removeEventListener('error', handleError);
      window.removeEventListener('unhandledrejection', handleRejection);
    };
  }, [router.events]);

  return (
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
        <UserProvider>
          <CartProvider>
            <OrderProvider>
              <Layout>
                <Head>
                  <meta name='viewport' content='initial-scale=1.0, width=device-width' key='viewport' />
                </Head>
                <HeadConfig />
                <Component {...pageProps} />
              </Layout>
            </OrderProvider>
          </CartProvider>
        </UserProvider>
    </ThemeProvider>
  );
};

export default MyApp;
